jQuery(document).ready(function() {

  $('.all-pages').foundation();

  $("a.button").wrapInner("<span></span>");

  $(".field.message").removeClass("message");

  $( ".team-leader" ).wrapAll( "<div class='row align-center team-leaders team-container'></div>" );

  $( ".team-member" ).wrapAll( "<div class='row align-center team-members team-container'></div>" );  

  var imgs = document.getElementsByTagName('img');
  for (var i=0; i<imgs.length; i++) {
    if (imgs[i].getAttribute('data-defer-src')) {
      imgs[i].setAttribute('src', imgs[i].getAttribute('data-defer-src'));
    }
  }  

});


jQuery(window).scroll(function() {    
    var scroll = jQuery(window).scrollTop();
    if (scroll >= 100) {
        jQuery(".sticky").addClass("shrink");
    } else {
        jQuery(".sticky").removeClass("shrink");
    }
});